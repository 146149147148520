import React from 'react';
import {IPropsInjectedByViewerScript} from '../types/app-types';
import {IHostProps, ISantaProps} from '@wix/native-components-infra/dist/es/src/types/types';
import {BackInStockStoreProps} from '../viewerScript/stores/BackInStockStore';

export const PropsContext = React.createContext<ProvidedGlobalProps>(undefined);

export interface ProvidedGlobalProps {
  globals?: GlobalProps;
}

export type GlobalProps = Pick<
  IPropsInjectedByViewerScript,
  | 'addedToCartStatus'
  | 'addToCartState'
  | 'breakdownParams'
  | 'reportBI'
  | 'closeWixModal'
  | 'cashierExpressCheckoutWidgetProps'
  | 'cashierExpressWidgetOnShippingMethodSelected'
  | 'cashierExpressWidgetOnShippingMethodSelectedResult'
  | 'cashierExpressPaymentBreakdown'
  | 'currentPrice'
  | 'dynamicPaymentsMethodsAmount'
  | 'dynamicPaymentMethodsEnabled'
  | 'fetchPaymentBreakdownForCashierAddress'
  | 'fetchPaymentBreakdownForCashierAddressResult'
  | 'errorPlacement'
  | 'errors'
  | 'experiments'
  | 'handleAddToCart'
  | 'handleBuyNow'
  | 'handleCashierOnClick'
  | 'handleCashierOnClickResult'
  | 'handleCashierPaymentSubmit'
  | 'handleCashierPaymentSubmitResult'
  | 'handleOpenModal'
  | 'handleSubscribe'
  | 'handleUserInput'
  | 'handleWishlistButtonClick'
  | 'hasMultipleMedia'
  | 'hideNavigationUrls'
  | 'htmlTags'
  | 'infoSection'
  | 'isDesktop'
  | 'isEditorMode'
  | 'isEditorX'
  | 'isInteractive'
  | 'isMobile'
  | 'isProductSubmitted'
  | 'isQuickView'
  | 'isRTL'
  | 'isSEO'
  | 'isSSR'
  | 'isPreOrderState'
  | 'layoutId'
  | 'modalState'
  | 'navigate'
  | 'navigateToLink'
  | 'onAppLoaded'
  | 'pagePath'
  | 'pagination'
  | 'product'
  | 'productUrl'
  | 'productWasAddedToWishlist'
  | 'quantityRange'
  | 'remainingItemCount'
  | 'resetAddedToCartStatus'
  | 'resetWishlistStatus'
  | 'selectedVariant'
  | 'setDynamicPaymentsMethodsAmount'
  | 'shouldFocusAddToCartButton'
  | 'shouldShowAddToCartButton'
  | 'shouldShowAddToCartSuccessAnimation'
  | 'shouldShowBuyNowButton'
  | 'shouldShowProductPaymentBreakdown'
  | 'shouldShowDiscountName'
  | 'shouldShowSubscribeButton'
  | 'shouldShowSubscriptionPlans'
  | 'shouldShowWishlistButton'
  | 'shouldShorUserInputs'
  | 'dynamicPaymentMethodsTheme'
  | 'siteUrl'
  | 'socialSharing'
  | 'subscriptionPlans'
  | 'priceBreakdown'
  | 'texts'
  | 'userInputErrors'
  | 'userInputs'
  | 'validate'
  | 'variantInfo'
  | 'wishlistActionStatus'
  | 'withModalGallery'
  | keyof BackInStockStoreProps
> &
  Pick<IHostProps, 'updateLayout' | 'dimensions' | 'style' | 'accessibilityEnabled'>;

export function withGlobalPropsProvider(Component) {
  //eslint-disable-next-line react/display-name
  return (props: IPropsInjectedByViewerScript & ISantaProps & {style: IHostProps['style']}) => {
    const {
      addToCartState,
      addedToCartStatus,
      breakdownParams,
      reportBI,
      cashierExpressCheckoutWidgetProps,
      cashierExpressWidgetOnShippingMethodSelected,
      cashierExpressWidgetOnShippingMethodSelectedResult,
      cashierExpressPaymentBreakdown,
      closeWixModal,
      currentPrice,
      dynamicPaymentsMethodsAmount,
      dynamicPaymentMethodsEnabled,
      errorPlacement,
      errors,
      experiments,
      fetchPaymentBreakdownForCashierAddress,
      fetchPaymentBreakdownForCashierAddressResult,
      handleAddToCart,
      handleBuyNow,
      handleCashierOnClick,
      handleCashierOnClickResult,
      handleCashierPaymentSubmit,
      handleCashierPaymentSubmitResult,
      handleNotifyWhenBackInStock,
      handleOpenModal,
      handleSubscribe,
      handleUserInput,
      handleWishlistButtonClick,
      hasMultipleMedia,
      hideNavigationUrls,
      htmlTags,
      infoSection,
      isBackInStockEnabled,
      isBackInStockLoading,
      isDesktop,
      isEditorMode,
      isEditorX,
      isInteractive,
      isMobile,
      isProductSubmitted,
      isQuickView,
      isRTL,
      isSEO,
      isSSR,
      isPreOrderState,
      layoutId,
      modalState,
      navigate,
      navigateToLink,
      dynamicPaymentMethodsTheme,
      onAppLoaded,
      pagePath,
      pagination,
      product,
      productUrl,
      productWasAddedToWishlist,
      quantityRange,
      remainingItemCount,
      resetAddedToCartStatus,
      resetWishlistStatus,
      selectedVariant,
      setDynamicPaymentsMethodsAmount,
      shouldFocusAddToCartButton,
      shouldShowAddToCartButton,
      shouldShowAddToCartSuccessAnimation,
      shouldShowBuyNowButton,
      shouldShowProductPaymentBreakdown,
      shouldShowDiscountName,
      shouldShowSubscribeButton,
      shouldShowSubscriptionPlans,
      shouldShowWishlistButton,
      shouldShorUserInputs,
      siteUrl,
      socialSharing,
      style,
      subscriptionPlans,
      priceBreakdown,
      texts,
      userInputErrors,
      userInputs,
      validate,
      variantInfo,
      wishlistActionStatus,
      withModalGallery,
      shouldShowBackInStockButton,
      ...passThroughProps
    } = props;

    const {dimensions, updateLayout, accessibilityEnabled} = props.host;

    const globals = {
      accessibilityEnabled,
      addToCartState,
      addedToCartStatus,
      breakdownParams,
      reportBI,
      cashierExpressCheckoutWidgetProps,
      cashierExpressWidgetOnShippingMethodSelected,
      cashierExpressWidgetOnShippingMethodSelectedResult,
      cashierExpressPaymentBreakdown,
      closeWixModal,
      currentPrice,
      dimensions,
      dynamicPaymentsMethodsAmount,
      dynamicPaymentMethodsEnabled,
      errorPlacement,
      errors,
      experiments,
      fetchPaymentBreakdownForCashierAddress,
      fetchPaymentBreakdownForCashierAddressResult,
      handleAddToCart,
      handleBuyNow,
      handleCashierOnClick,
      handleCashierOnClickResult,
      handleCashierPaymentSubmit,
      handleCashierPaymentSubmitResult,
      handleNotifyWhenBackInStock,
      handleOpenModal,
      handleSubscribe,
      handleUserInput,
      handleWishlistButtonClick,
      hasMultipleMedia,
      hideNavigationUrls,
      htmlTags,
      infoSection,
      isBackInStockEnabled,
      isBackInStockLoading,
      isDesktop,
      isEditorMode,
      isEditorX,
      isInteractive,
      isMobile,
      isProductSubmitted,
      isQuickView,
      isRTL,
      isSEO,
      isSSR,
      isPreOrderState,
      layoutId,
      modalState,
      navigate,
      navigateToLink,
      onAppLoaded,
      pagePath,
      pagination,
      product,
      productUrl,
      productWasAddedToWishlist,
      quantityRange,
      remainingItemCount,
      resetAddedToCartStatus,
      resetWishlistStatus,
      selectedVariant,
      setDynamicPaymentsMethodsAmount,
      shouldFocusAddToCartButton,
      shouldShowAddToCartButton,
      shouldShowAddToCartSuccessAnimation,
      shouldShowBuyNowButton,
      shouldShowProductPaymentBreakdown,
      shouldShowDiscountName,
      shouldShowSubscribeButton,
      shouldShowSubscriptionPlans,
      shouldShowWishlistButton,
      shouldShorUserInputs,
      dynamicPaymentMethodsTheme,
      siteUrl,
      socialSharing,
      style,
      subscriptionPlans,
      priceBreakdown,
      texts,
      updateLayout,
      userInputErrors,
      userInputs,
      validate,
      variantInfo,
      wishlistActionStatus,
      withModalGallery,
      shouldShowBackInStockButton,
    };

    return (
      <PropsContext.Provider value={{globals}}>
        <Component {...passThroughProps} />
      </PropsContext.Provider>
    );
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  //eslint-disable-next-line react/display-name
  return (props) => {
    return <PropsContext.Consumer>{(globalProps) => <Component {...globalProps} {...props} />}</PropsContext.Consumer>;
  };
}

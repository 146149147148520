import React from 'react';

import {Cell} from '../Cell/Cell';
import {Navigation} from '../../Navigation/Navigation';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';
import classNames from 'classnames';
import {LayoutComponentProps} from '../../../types/app-types';
import {convertCssValueToConfig} from '../../../commons/utils';
import s from './SpotlightLayout.scss';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';
import {ProductDiscountName} from '../../ProductDiscountName/ProductDiscountName';

export const SpotlightLayout: React.FunctionComponent<LayoutComponentProps> = ({product, settings, globals}) => {
  const headerClass = classNames(s.header, {[s.fullWidth]: settings.isFullWidth});
  const footerClass = classNames(s.footer, {[s.fullWidth]: settings.isFullWidth});
  const showAutomaticDiscountDataOnProductPage = globals.experiments?.showAutomaticDiscountDataOnProductPage;

  return (
    <article>
      <div className={s.headerContainer}>
        <div className={headerClass}>
          {settings.shouldShowNavigation && <Navigation className={s.navigation} />}
          <article>
            <section className={s.col}>
              <Cell className={s.gallery}>
                <ProductGalleryLayout
                  product={product}
                  layoutConfig={createLayoutConfigWithDefaults({
                    withDynamicHeight: true,
                    dimensions: {
                      mainMedia: {
                        widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
                        heightConf: convertCssValueToConfig(s.sharedStyleVariables_galleryHeight),
                      },
                      thumbnails: {
                        widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
                        heightConf: {num: 50, unit: 'px'},
                      },
                    },
                  })}
                  {...settings}
                />
              </Cell>
            </section>
            <section className={s.col}>
              <Cell>
                <ProductName name={product.name} />
              </Cell>

              {settings.shouldShowSku && (
                <Cell className={s.sku}>
                  <ProductSku />
                </Cell>
              )}

              {settings.shouldShowPrice && (
                <Cell className={s.price}>
                  <ProductPrice />
                </Cell>
              )}

              {settings.shouldShowDiscountName && showAutomaticDiscountDataOnProductPage && (
                <Cell>
                  <ProductDiscountName name={product.itemDiscount.discountRuleName} />
                </Cell>
              )}

              {settings.shouldShowProductPaymentBreakdown && (
                <Cell>
                  <ProductPaymentBreakdown />
                </Cell>
              )}

              <Cell>
                <DetailsPlaceholder />
              </Cell>

              <Cell className={s.description}>
                <ProductDescription description={product.description} />
              </Cell>

              <Cell className={s.options}>
                <ProductOptions
                  shouldShowQuantity={settings.shouldShowQuantity}
                  shouldShowStockIndicator={settings.shouldShowStockIndicator}
                />
                <ProductPageButtonsContainer />
              </Cell>

              {settings.shouldShowSocialNetwork && (
                <Cell className={s.social}>
                  <ProductSocial />
                </Cell>
              )}
            </section>
          </article>
        </div>
      </div>
      {settings.shouldShowInfoSection && product.additionalInfo && product.additionalInfo.length > 0 && (
        <div className={s.footerContainer}>
          <div className={footerClass}>
            <Cell className={s.info}>
              <InfoSectionLayout />
            </Cell>
          </div>
        </div>
      )}
      <Cell>
        <BottomPlaceholder />
      </Cell>
    </article>
  );
};

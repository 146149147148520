import React from 'react';
import {Navigation} from '../../Navigation/Navigation';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';

import s from './SleekLayout.scss';
import {Cell} from '../Cell/Cell';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {LayoutComponentProps} from '../../../types/app-types';
import {convertCssValueToConfig} from '../../../commons/utils';
import {MarginBottom} from '../../../constants';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';
import {ProductDiscountName} from '../../ProductDiscountName/ProductDiscountName';

export const SleekLayout: React.FunctionComponent<LayoutComponentProps> = ({product, settings, globals}) => {
  const showAutomaticDiscountDataOnProductPage = globals.experiments?.showAutomaticDiscountDataOnProductPage;
  return (
    <article className={s.container}>
      <Cell className={s.main}>{settings.shouldShowNavigation && <Navigation className={s.navigation} />}</Cell>
      <div className={s.headerContainer}>
        <header className={s.header}>
          <ProductGalleryLayout
            product={product}
            layoutConfig={createLayoutConfigWithDefaults({
              marginBottom: MarginBottom.LARGE,
              dimensions: {
                mainMedia: {
                  widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
                  heightConf: convertCssValueToConfig(s.sharedStyleVariables_galleryHeight),
                },
                thumbnails: {
                  widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
                  heightConf: {num: 50, unit: 'px'},
                },
              },
            })}
            {...settings}
          />
        </header>
      </div>
      <div className={s.main}>
        <section className={s.section}>
          <Cell className={s.title}>
            <ProductName name={product.name} />
          </Cell>
          {settings.shouldShowPrice && (
            <Cell className={s.price}>
              <ProductPrice />
            </Cell>
          )}

          {settings.shouldShowDiscountName && showAutomaticDiscountDataOnProductPage && (
            <Cell>
              <ProductDiscountName name={product.itemDiscount.discountRuleName} />
            </Cell>
          )}

          {settings.shouldShowProductPaymentBreakdown && (
            <Cell>
              <ProductPaymentBreakdown />
            </Cell>
          )}

          <Cell>
            <DetailsPlaceholder />
          </Cell>
          <div>
            <Cell className={s.description}>
              <ProductDescription description={product.description} />
            </Cell>
          </div>
          <div>
            <Cell className={s.options}>
              <ProductOptions
                shouldShowQuantity={settings.shouldShowQuantity}
                shouldShowStockIndicator={settings.shouldShowStockIndicator}
              />{' '}
              <ProductPageButtonsContainer />
            </Cell>
          </div>
          {settings.shouldShowSku && (
            <Cell className={s.sku}>
              <ProductSku />
            </Cell>
          )}
          {settings.shouldShowSocialNetwork && (
            <Cell className={s.social}>
              <ProductSocial />
            </Cell>
          )}
        </section>
      </div>
      <div className={s.footerContainer}>
        {settings.shouldShowInfoSection && (
          <footer className={s.footer}>
            <Cell className={s.info}>
              <InfoSectionLayout />
            </Cell>
          </footer>
        )}
      </div>
      <Cell>
        <BottomPlaceholder />
      </Cell>
    </article>
  );
};
